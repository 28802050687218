/******* screen-small.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2017-01-10 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #808080;
  color: #808080;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 0;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #333 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.6em;
  font-size: 0.7em;
}
sub {
  top: 0.6em;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #666;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 6px;
}
.vert td,
.vert th {
  padding: 6px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  background: #666;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #808080;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #4a626a;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #960000;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #960000;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1200px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #666;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 6px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #666;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 6px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 6px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #666;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 6px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #666;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 6px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 6px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #808080;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #666;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 6px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 6px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #666;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 6px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible {
  display: none;
}
/*.cb-mobile .body-mobile {
	display: block;
}*/
/******* site.less 2017-10-13 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(150, 0, 0, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 20px;
  font-size: 2rem;
  width: 100%;
  background-color: transparent;
  color: #333;
  font-family: 'Ropa Sans', sans-serif;
  line-height: 1.3;
}
#home {
  float: left;
}
.logo {
  width: 100%;
  height: auto;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.mood {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin-top: 50px;
}
.c2 .mood {
  background-image: none !important;
  background-color: #aaa;
}
#edit {
  background-color: #f5f5f5;
}
.topnav-wrapper,
.footer {
  color: #fff;
  background: #000;
  /*  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
.topnav-wrapper ::-moz-selection,
.footer ::-moz-selection {
  color: #fff;
  background: #ff906c;
}
.topnav-wrapper ::selection,
.footer ::selection {
  color: #fff;
  background: #ff906c;
}
.footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer .desk {
  position: relative;
}
.topnav-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}
#work + .topnav-wrapper {
  top: 50px;
  position: absolute;
}
.topnav-wrapper a {
  color: currentColor;
}
.topnav {
  line-height: 1.1;
  float: right;
  text-transform: uppercase;
}
.service_member {
  text-transform: uppercase;
}
#cmsbox {
  float: left;
  margin-top: 30px;
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
}
#cmsbox .meta {
  color: #ff906c;
  padding: 0 5px;
}
#cmsbox .meta:first-child {
  padding-left: 0;
}
#cmsbox .meta:last-child {
  padding-right: 0;
}
#cmsbox .meta:hover,
#cmsbox .meta:focus {
  color: #fff;
}
.north-wrapper {
  *zoom: 1;
}
.north-wrapper:before,
.north-wrapper:after {
  display: table;
  content: '';
}
.north-wrapper:after {
  clear: both;
}
.southwrapper {
  position: absolute;
  top: 272px;
  right: 42px;
  z-index: 1900;
}
#edit .southwrapper {
  position: static;
  width: 100%;
  margin-top: 100px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#view.south--empty .southwrapper {
  display: none !important;
}
@media (max-width: 1199px) {
  .southwrapper {
    top: 260px;
  }
}
@media (max-width: 599px) {
  .southwrapper {
    right: 30px;
    top: 113px;
  }
}
.southwrapper__desk {
  float: left;
}
#edit .southwrapper__desk {
  width: 70%;
}
.southcontent {
  float: left;
  position: relative;
}
.southbg {
  float: left;
  width: 500px;
  max-width: calc(100vw - 84px);
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}
#view .southbg {
  display: none;
}
@media (min-width: 1200px) {
  #view .southcontent:hover .southbg {
    display: block;
  }
}
@media (max-width: 1199px) {
  #view .southcontent.cb-toggle-target-active .southbg {
    display: block;
  }
}
@media (max-width: 599px) {
  .southbg {
    max-width: calc(100vw - 30px);
  }
}
.southbg .area {
  width: 100%;
  color: #fff;
}
.southbg .area a {
  color: #ff906c !important;
  text-decoration: none;
}
.southbg .area a:hover,
.southbg .area a:focus {
  color: #fff !important;
  text-decoration: none;
}
.southbg .area .unit {
  margin: 0 !important;
  width: 100% !important;
}
.southbg .area .unit .body {
  margin: 0;
}
.southbg .area .unit .part,
.southbg .area .unit div.head h2 {
  margin: 0 !important;
  width: 100% !important;
}
@media (max-width: 1199px) {
  .southbg .area {
    padding-bottom: 40px;
  }
}
.southtoggle {
  position: absolute;
  right: -9px;
  top: -9px;
  z-index: 2;
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/southtoggle.svg);
  cursor: pointer;
}
.southtoggle:hover,
.southtoggle:focus {
  background-image: url(/images/southtoggle-hover.svg);
}
.closesouth {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/closesouth.svg);
  cursor: pointer;
}
@media (max-width: 1199px) {
  .closesouth {
    display: block;
  }
}
.maincontent {
  *zoom: 1;
  display: block;
  background: #fff;
}
.maincontent:before,
.maincontent:after {
  display: table;
  content: '';
}
.maincontent:after {
  clear: both;
}
.aside {
  *zoom: 1;
  background: #dbe7eb;
}
.aside:before,
.aside:after {
  display: table;
  content: '';
}
.aside:after {
  clear: both;
}
.footer {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 45px 0 70px;
}
.social,
.social__services,
.social__hint {
  float: left;
  width: 100%;
}
.social {
  margin-top: 47px;
}
#social {
  font-size: 0;
  margin-left: -5px;
}
#social .service_facebook,
#social .service_twitter,
#social .service_linkedin {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: inline-block;
  width: 33px;
  height: 33px;
  border-radius: 5px;
  margin: 5px;
  background: #fff url(/images/service_facebook--invert.svg) no-repeat;
  background-size: 33px 33px;
  -webkit-tap-highlight-color: transparent;
}
#social .service_facebook:hover,
#social .service_twitter:hover,
#social .service_linkedin:hover,
#social .service_facebook:focus,
#social .service_twitter:focus,
#social .service_linkedin:focus {
  background-color: #c94027;
}
#social .service_facebook:active,
#social .service_twitter:active,
#social .service_linkedin:active {
  background-color: #b43923;
}
#social .service_twitter {
  background-image: url(/images/service_twitter--invert.svg);
}
#social .service_linkedin {
  background-image: url(/images/service_linkedin.svg);
}
.social__hint {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
.social__hint .meta {
  color: #ff906c;
}
.social__hint .meta:hover,
.social__hint .meta:focus {
  color: #fff;
}
.cb-authenticated .service_member {
  display: none;
}
.topnav .meta.auth {
  display: none;
}
.cb-authenticated .topnav .meta.auth {
  display: block;
}
.navi,
.navi > .item {
  float: left;
}
/*.navi > .item {
  position: relative;
}*/
.menu {
  color: #fff;
  line-height: 1.1;
  display: block;
}
.menu:hover,
.menu:focus {
  color: #ff906c;
}
a.here {
  color: #fff !important;
}
div.sub1 {
  float: right;
  margin-right: 5px;
}
div.sub1 .menu {
  position: relative;
  padding: 0 0.3em;
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 50px;
}
div.sub1 .menu:after {
  content: '|';
  color: #fff !important;
  position: absolute;
  top: -0.1em;
  right: -0.15em;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.exit > .menu:after {
  display: none;
}
.sub1 a.path {
  font-weight: 700;
}
div.sub2,
div.sub3 {
  width: 100%;
}
div.sub2 .item {
  /*
  both main navigation & footer navigation
  */
  width: 100%;
}
div.sub2 .menu {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  padding: 1.35em 0;
}
/*.sub2 > .init > .menu {
  padding-top: 0;
}*/
div.sub3 .menu {
  font-size: 16px;
  font-size: 1.6rem;
  border: solid #666;
  border-width: 1px 0;
  margin-bottom: -1px;
  text-transform: none;
  padding: 0.25em 0;
  letter-spacing: normal;
}
div.sub3 {
  padding-bottom: 16px;
}
.mood--large {
  float: left;
  width: 100%;
  position: relative;
}
.mood--large:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 100%;
  transition: background 0.4s;
  z-index: 1998;
}
html.cb-toggle-target-active .mood--large:after {
  bottom: 0;
}
.geobimTitle {
  float: left;
  margin-top: 30px;
  margin-left: 30px;
  color: #fff !important;
  font-size: 44px;
  line-height: 1;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  display: none;
}
.c2 .geobimTitle {
  display: block;
}
@media (max-width: 599px) {
  .geobimTitle {
    font-size: 30px;
    margin-left: 20px;
  }
}
.unit h2 {
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
}
h1,
.cb-layout1 .content .unit:first-child h2 {
  font-family: 'Titillium Web', sans-serif;
  line-height: 1;
  font-size: 50px;
  font-size: 5rem;
  font-weight: 200;
}
#head {
  width: 100%;
}
#view.cb-layout1 h1 {
  /*font-weight: 200;
  line-height: 1;
  font-size: 8vw; // hacks
  color: @white;
  text-transform: uppercase;
  hyphens: none;*/
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/layout1-h1--de-2023-02-02.svg) no-repeat;
  background-size: 100% auto;
  padding-bottom: 15.35714286%;
  width: 100%;
}
#view.en.cb-layout1 h1 {
  background-image: url(/images/layout1-h1--en-2018-12-04.svg);
}
#view.fr.cb-layout1 h1 {
  background-image: url(/images/layout1-h1--fr-2018-12-04.svg);
}
.cb-layout2 #head {
  margin-top: 42px;
}
h3,
.form .name {
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  letter-spacing: -0.016em;
}
strong {
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
div.load {
  background: none !important;
}
a.load.load {
  display: inline-block;
  border-radius: 5px;
  padding: 0.1em 0.5em;
  padding-left: 29px;
  border: 1px solid #333;
  background: url(/images/a-load.svg) no-repeat 8px 0.22em;
  background-size: auto 1em;
}
a.load:active {
  color: #000;
}
a.load:visited {
  color: #4a626a;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #960000;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #c93f26;
}
.c2 .unit a {
  color: #003c8d;
}
.c2 .unit a:hover,
.c2 .unit a:focus {
  color: #3a75c4;
}
.text .open:hover,
.lead .open:hover,
.list .open:hover,
.text .open:focus,
.lead .open:focus,
.list .open:focus {
  text-decoration: underline;
}
.text .open:active,
.lead .open:active,
.list .open:active {
  color: #000;
}
.text .open:visited,
.lead .open:visited,
.list .open:visited {
  color: #4a626a;
}
.list + .part:not(.list),
.part:not(.list) + .list {
  margin-top: 0.8em;
}
.list:first-child {
  margin-top: 0.4em !important;
}
.link {
  background: none;
}
.link > .open {
  padding: 0.3em 0.7em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff !important;
  background: #960000;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
.c2 .link > .open {
  background-color: #003c8d;
}
.c2 .link > .open:hover,
.c2 .link > .open:focus {
  background-color: #3a75c4;
}
.content .link > .open {
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.2em;
}
/*.load {
  background-position: 0 .3em;
}*/
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
#view .side:empty {
  display: none;
}
div.main {
  padding-bottom: 20px;
}
.text + .lead {
  margin-top: 1.1em;
}
.lead + .text {
  margin-top: 0;
}
div.auth input.text,
div.auth input.password {
  border-radius: 0;
  border-color: #999999;
  min-height: 34px;
}
div.auth input.submit {
  float: left;
  margin-top: 10px;
  min-height: 0;
  line-height: 1;
  min-height: 34px;
  padding: 0.35em 0.7em 0.25em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
  background: #960000;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  -webkit-appearance: none;
}
div.auth input.submit:hover,
div.auth input.submit:focus {
  background: #c93f26;
}
div.auth input.submit:active {
  background: #000;
}
.north .unit,
.north .body {
  margin-top: 0;
  margin-bottom: 0;
}
.north .link > .open {
  line-height: 1;
  background: #6b121a;
  padding: 0.5em 0.4em 0.4em 0.7em;
}
.link > .open {
  -webkit-tap-highlight-color: transparent;
}
.link > .open:hover,
.link > .open:focus {
  background: #c93f26;
}
.north .link > .open:hover,
.north .link > .open:focus {
  background: #960000;
}
.north .seam .link > .open {
  background: #4a626a;
}
.north .seam .link > .open:hover,
.north .seam .link > .open:focus {
  background: #688290;
}
.link > .open.open.open:active {
  color: #fff;
  background: #000;
}
.content .seam > .body,
.content .seam .part {
  margin-top: 0;
  margin-bottom: 0;
}
.content .seam h2 {
  color: #960000;
  font-family: 'Ropa Sans', sans-serif;
  font-size: 1em;
  font-weight: normal;
}
.cb-layout2 .side > .unit:first-child h2,
.cb-layout1 .side > .unit:first-child h2 {
  font-weight: normal;
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  color: #333;
  font-size: 19px;
  font-size: 1.9rem;
  margin-top: 2px;
}
.side .unit.pure:first-child + .seam {
  margin-top: -3px;
}
.flat h2 {
  font-weight: normal;
  font-family: 'Ropa Sans', sans-serif;
}
.flat > .body,
.flat .part {
  margin-top: 0;
  margin-bottom: 0;
}
.flat > .body.load,
.flat .part.load {
  margin-top: 0.8em;
}
.flat h3 {
  font-weight: 700;
  letter-spacing: normal;
}
div.fold + div.fold {
  margin-top: -10px !important;
}
.fold .head {
  position: relative;
}
.fold h2 .fold-toggle {
  display: block;
  padding: 0.82em;
  padding-right: 0;
  padding-left: 40px;
  border-bottom: 1px solid #dbe7eb;
  color: #333;
}
.fold h2 .fold-toggle:after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0.82em;
  /*left: 0;*/
  border-radius: 50%;
  background: #333 url(/images/cb-folding-toggle.svg) no-repeat 50% 50%;
  transition: transform 0.218s cubic-bezier(0.43, 1.78, 0.27, 0.82);
}
.fold h2 .fold-toggle:hover:after,
.fold h2 .fold-toggle:focus:after {
  background-color: #000;
}
.fold h2 .fold-toggle.fold-toggle--open:after {
  transform: rotate(90deg);
}
.fold > .body {
  margin: 0;
}
.fold .more .part:first-child {
  margin-top: 1.3em;
}
.link.more,
.link.less {
  display: none;
}
.cb-form-send.cb-form-send {
  display: block;
  margin-left: 0;
  padding: 0.3em 0.7em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff !important;
  background: #960000;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0.12em;
  -webkit-appearance: none;
}
.c2 .cb-form-send.cb-form-send {
  background-color: #003c8d;
}
.c2 .cb-form-send.cb-form-send:hover,
.c2 .cb-form-send.cb-form-send:focus {
  background-color: #3a75c4;
}
.unit:not(.fold) + .fold {
  margin-top: 43px;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2016-01-05 *******/
/* Changelog

2016-01-05
added Calendar (partial copy of manage.css)

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #262626;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Ropa Sans', sans-serif;
  font-size: 20px;
  font-size: 2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 20px;
  font-size: 2rem;
  color: #444;
  font-weight: normal;
  font-family: 'Ropa Sans', sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #737373;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 20px;
  font-size: 2rem;
  display: inline-block;
  padding: 0.1em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #960000;
  border-radius: 2px;
  background-color: #960000;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #c93f26;
  background-color: #c93f26;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #000;
  background-color: #000;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  line-height: 1.5;
  position: absolute;
  z-index: 100;
  text-align: right;
  border: 3px solid #666;
  background: #999;
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  font-weight: 700;
  text-align: center;
  color: #eee;
}
.calendar tbody {
  color: #999;
  background-color: #eee;
}
td.show {
  font-weight: 700;
  color: #fff;
  background: #7be;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.calendar th > a:hover,
.calendar td > a:hover,
.calendar th > a:focus,
.calendar td > a:focus {
  color: #fff;
  background: #ccc;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
.tick .ctrl.fail {
  float: none;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/
.togglenavigation {
  display: inline-block;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  position: relative;
}
.togglenavigation__outer {
  width: 40px;
  height: 22px;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 14px;
}
.togglenavigation__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.togglenavigation__inner,
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  width: 29px;
  height: 4px;
  background-color: #fff;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.14s;
  transition-timing-function: ease;
}
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  content: "";
  display: block;
}
.togglenavigation__inner::before {
  top: -11px;
}
.togglenavigation__inner::after {
  bottom: -11px;
}
/*
 * Squeeze
 */
.togglenavigation--squeeze .togglenavigation__inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.togglenavigation--squeeze .togglenavigation__inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}
.togglenavigation--squeeze .togglenavigation__inner::after {
  transition: bottom 0.1s 0.14s ease, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
/*.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner,
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::before,
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::after {
  background: @s_middlegray;
}*/
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/******* layout-small.less 2013-1-16 *******/
@media (max-width: 599px) {
  .mood--large {
    background-image: none !important;
  }
  .topnav .service_member,
  .topnav .meta.auth {
    display: none !important;
  }
}
@media (max-width: 599px) and (max-width: 599px) {
  .service_member {
    display: block;
    margin-left: 0;
    padding: 0.3em 0.7em;
    font-size: 18px;
    font-size: 1.8rem;
    color: #fff !important;
    background: #960000;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    clear: both;
    text-align: center;
    letter-spacing: 0.1em;
  }
  .c2 .service_member {
    background-color: #003c8d;
  }
  .c2 .service_member:hover,
  .c2 .service_member:focus {
    background-color: #3a75c4;
  }
  .service_member:hover,
  .service_member:focus {
    color: #fff;
    background: #c93f26;
  }
  .service_member:active {
    background: #000;
  }
}
#home {
  width: 40.68965517%;
  margin-top: 29px;
}
.desk {
  width: 90%;
}
.desk--content {
  width: 100%;
}
html.cb-toggle-target-active .mood--large:after {
  background: #333;
}
.togglenavigation,
.toggle-contacts {
  border-radius: 0;
}
.togglenavigation {
  float: right;
}
/*.togglenavigation,
.toggle-contacts,
#home {
  margin-top: 20px;
}*/
.togglenavigation {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
}
.mood--small {
  margin-top: 0;
}
.cb-layout1 .mood--small {
  padding-bottom: 19px;
}
@media (max-width: 599px) {
  html.cb-toggle-target-active .mood--small {
    display: none;
  }
}
.north-wrapper {
  background: #999;
  padding: 46px 0;
}
.cb-layout1 #head {
  margin-top: 62px;
}
.cb-layout2 #head {
  width: 90%;
  margin-left: 5%;
}
.cb-layout2 .mood--small {
  padding-bottom: 89px;
}
.navigation {
  display: none;
}
html.cb-toggle-target-active .navigation {
  display: block;
  position: relative;
  z-index: 1999;
}
@media (max-width: 599px) {
  .navigation .sub2 {
    margin-top: 15px;
    margin-bottom: 36px;
  }
  .navigation .sub2 > .item {
    margin-top: 18px;
  }
  .navigation .sub2 .menu {
    padding: 1.5em 0;
  }
}
div.sub3 .menu.menu {
  padding: 0.8em 0;
}
.main,
.side {
  width: 100%;
}
.main > .unit,
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main h2,
.side h2,
.main .foot,
.side .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.main .part,
.side .part,
.main > .grid table,
.side > .grid table {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.main > .slim .part,
.side > .slim .part,
.main > .slim.grid table,
.side > .slim.grid table {
  width: 90%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.north .part,
.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 100%;
}
.main {
  margin-top: 31px;
}
.side {
  margin-top: 85px;
}
.cb-layout2 .main {
  margin-top: 33px;
}
/*.cb-layout1 .main .pure:first-child .body {
  margin-top: 53px;
}
*/
/*.main .unit:first-child {
  margin-top: 54px;
}*/
.content .unit.unit:last-child {
  margin-bottom: 54px;
}
/*
.side > .unit:last-child {
  margin-bottom: 54px;
}*/
.side .unit.pure + .seam {
  /*
  veranstaltung
  */
  margin-top: 45px;
}
/*.cb-layout2 .main .pure:first-child h2 {
  margin-top: 20px;
}*/
.content .link > .open {
  display: block;
  text-align: center;
}
.north .link {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.north .link > .open {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 0.03em;
}
.fold h2 .fold-toggle:after {
  left: 5%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
.unit h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-security.less 2013-1-16 *******/
.cb-part-foot {
  clear: both;
  margin: 0 !important;
}
.cb-part-body {
  /* only used in authentication part */
  margin: 0.4em 0;
}
.auth fieldset {
  padding: 0.2em 0;
}
.auth .text,
.auth .password {
  float: right;
  width: 100%;
}
.auth .ctrl .sign {
  display: block;
  margin-left: 20px;
  line-height: 19px;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */